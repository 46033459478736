<template>
  <div>
    <lp-datatable
      ref="referralTable"
      title="Referral"
      store-module="affiliate"
      store-data-endpoint="getAffiliateList"
      store-getter-endpoint="affiliateList"
      pagination-state-key="affiliateList"
      :features="loadedConfig.datatableFeatures"
      :load-system-constant="true"
      default-search-field="name"
      default-sort-field="created_at"
      :search-options-dropdown="loadedConfig.searchOptsDropdown"
      :conf="loadedConfig"
      @actionBtn-clicked="actionBtnClicked"
    />

    <b-modal
      ref="viewReferralRecordsModal"
      ok-only
      size="lg"
      no-close-on-backdrop
      header-bg-variant="primary"
      header-text-variant="light"
      header-close-variant="dark"
      title="Referral Record"
    >
      <b-table
        striped
        hover
        :fields="fields"
        :items="selectedRowReferralRecord"
        show-empty
      >

        <template #empty>
          <div class="text-center p-2">
            No Data
          </div>
        </template>
      </b-table>
    </b-modal>

    <b-modal
      ref="viewReferralUserDataModal"
      ok-only
      no-close-on-backdrop
      header-bg-variant="primary"
      header-text-variant="light"
      header-close-variant="dark"
      title="User Info"
    >

      <!--      <b-form-group>-->
      <!--        <label>ID </label>-->
      <!--        <b-form-input-->
      <!--          v-model="selectedRowProfile.id"-->
      <!--          disabled-->
      <!--        />-->
      <!--      </b-form-group>-->
      <b-form-group>
        <label>Full Name </label>
        <b-form-input
          v-model="selectedRowProfile.full_name"
          disabled
        />
      </b-form-group>
      <b-form-group>
        <label>Email
          <template v-if="selectedRowProfile.email_verified">(Verified)</template>
          <template v-if="!selectedRowProfile.email_verified">(Not Verified)</template>
        </label>
        <b-form-input
          v-model="selectedRowProfile.email"
          disabled
        />
      </b-form-group>
      <b-form-group>
        <label>Phone Number </label>
        <b-form-input
          v-model="selectedRowProfile.phone_number"
          disabled
        />
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import Config from '@/views/administration/lxReferral/formConfig'
import humaniseMixin from '@/common/humanise.mixin'
import LpDatatable from '@/leanpay-components/LPDataTable.vue'

export default {
  components: { ValidationProvider, LpDatatable },
  mixins: [humaniseMixin],
  data() {
    return {
      cardTitle: 'Referral',
      dataGridEndpoint: [],
      module: 'affiliate',

      // DataGridSetting
      filter: '',
      searchTerm: '',
      currentPage: 1,
      isBusy: true,
      selectedPerPage: 50,
      sortBy: 'created_at',
      sortDesc: true,

      record_status: 1, // active only
      searchField: 'name',

      selectedRowReferralRecord: [],
      selectedRowProfile: {},

      fields: [
        {
          key: 'affiliate_id',
          sortable: true,
        },
        {
          key: 'affiliate_merchant_account_id',
          sortable: false,
        },
        {
          key: 'transaction_amount',
          sortable: false,
        },
        {
          key: 'transaction_date',
          sortable: false,
        },
        {
          key: 'is_active_account',
          sortable: false,
        },
        {
          key: 'is_paid',
          sortable: false,
        },
      ],

      searchFieldOpts: [
        { value: 'name', text: 'Name' },
        { value: 'nric', text: 'NRIC' },
        { value: 'affiliate_code', text: 'Code' },
        { value: 'account_bank_name', text: 'Account Holder Bank Name' },
      ],
    }
  },
  computed: {
    loadedConfig() {
      if (Config) {
        return Config
      }
      return {}
    },
  },
  async mounted() {
    this.isBusy = false
    const breadcrumbUpdatePayload = [
      { title: 'Referrals' },
    ]
    await this.$store.dispatch('breadcrumbs/setBreadcrumb', breadcrumbUpdatePayload)
    await this.$store.dispatch('systemConstants/getSystemConstants')

    // this.initDataGrid()
  },
  methods: {
    async actionBtnClicked(e) {
      if (e.actionName === 'viewProfile') {
        this.selectedRowProfile = e.rowData.users
        this.$refs.viewReferralUserDataModal.show()
      }

      if (e.actionName === 'viewReferralRecords') {
        this.selectedRowReferralRecord = e.rowData.affiliate_records
        this.$refs.viewReferralRecordsModal.show()
      }
    },
  },
}
</script>
