const Config = {
  datatableFeatures: {
    buttons: {
      refresh: true,
    },
    tableActionField: false,
    datepicker: false,
    showDTDetails: true,
    perPageDropdown: true,
    searchField: false,
    filter: false,
    pagination: true,
    search: true,
  },
  searchOptsDropdown: [
    { value: 'name', text: 'Name' },
    { value: 'nric', text: 'NRIC' },
    { value: 'affiliate_code', text: 'Code' },
    { value: 'account_bank_name', text: 'Account Holder Bank Name' },
  ],
  formData() {
    return {
      title: {
        edit: 'Edit Custom Plan',
        view: 'View Custom Plan',
        remove: 'Remove Custom Plan',
        create: 'Create New Custom Plan',
      },
      form: [],
    }
  },
  tableData() {
    return {
      tableAction: [
        // {
        //   enable: true, param: 'id', btnLabel: 'Approve Payout', action: 'custom', btnIcon: '', btnOnly: false, variant: 'secondary', size: 'sm', iconSize: '12', actionParam: 'approvePayout', disableCondition: 'status:COMPLETED',
        // },
        {
          enable: true, param: 'id', btnLabel: 'View Profile', action: 'custom', btnIcon: '', btnOnly: false, variant: 'info', size: 'sm', iconSize: '12', actionParam: 'viewProfile', disableCondition: 'status:COMPLETED',
        },
        {
          enable: true, param: 'id', btnLabel: 'Referrals', action: 'custom', btnIcon: '', btnOnly: false, variant: 'info', size: 'sm', iconSize: '12', actionParam: 'viewReferralRecords', disableCondition: 'status:COMPLETED',
        },
        // {
        //   enable: false, param: 'id', btnLabel: 'View', action: 'view', btnIcon: 'SearchIcon', btnOnly: true, variant: 'primary', size: 'sm', iconSize: '12',
        // },
        // {
        //   enable: false, param: 'id', btnLabel: 'Edit', action: 'edit', btnIcon: 'Edit2Icon', btnOnly: true, variant: 'info', size: 'sm', iconSize: '12', pageRoute: 'administration-plans-form',
        // },
        // {
        //   enable: false, param: 'id', btnLabel: 'Remove', action: 'remove', btnIcon: 'Trash2Icon', btnOnly: true, variant: 'danger', size: 'sm', iconSize: '12',
        // },
      ],
      tableHeader: [
        {
          key: 'show_details', label: '', sortable: false, visible: true, type: 'showDetails', class: 'show-details col-width',
        },
        {
          key: 'id', label: 'ID', sortable: true, visible: false, type: 'string',
        },
        {
          key: 'name', label: 'Name', sortable: true, visible: true, type: 'string',
        },
        {
          key: 'is_complete_profile', label: 'Profile Complete', sortable: true, visible: true, type: 'boolFlag',
        },
        {
          key: 'nric', label: 'NRIC', sortable: true, visible: true, type: 'string',
        },
        {
          key: 'affiliate_code', label: 'Code', sortable: true, visible: true, type: 'string',
        },
        {
          key: 'affiliate_records', label: 'Affiliate Records', sortable: false, visible: false, showInDetails: false,
        },
        {
          key: 'affiliate_uuid', label: 'Affiliate UUID', sortable: false, visible: false, showInDetails: true,
        },
        {
          key: 'account_bank_name', label: 'Bank Account Holder Name', sortable: false, visible: false, showInDetails: true,
        },
        {
          key: 'account_number', label: 'Bank Account Number', sortable: false, visible: false, showInDetails: true,
        },

        {
          key: 'payout_service_id', label: 'Bank Name', sortable: false, visible: false, showInDetails: true,
        },

        {
          key: 'users', label: 'User Name', sortable: false, visible: false, showInDetails: false,
        },
        // {
        //   key: 'referral_key', label: 'Referral Key', sortable: true, visible: false, type: 'string', showInDetails: true,
        // },
        {
          key: 'affiliate_total_amount', label: 'Total Amount', sortable: true, visible: true, type: 'monetary',
        },
        {
          key: 'is_payout', label: 'Payout', sortable: true, visible: true, type: 'boolFlag',
        },
        // {
        //   key: 'is_referral', label: 'Is Referral', sortable: true, visible: false, type: 'boolFlag',
        // },
        // {
        //   key: 'created_at', label: 'Created At', sortable: true, visible: false, class: 'text-right', type: 'date',
        // },
        {
          key: 'updated_at', label: 'Updated At', sortable: true, visible: true, type: 'date',
        },
        {
          key: 'actions',
          label: 'Action',
          class: 'text-right',
          visible: true,
        },
      ],
    }
  },
}

export default Config
